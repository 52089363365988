import React, {Component} from 'react';
import button1 from '../assets/img/heartFirst.png';
import button2 from '../assets/img/heartWings.png';
import wolfs from '../assets/img/wolfs.png';
import openedEnvelope from '../assets/img/openedEnvelope.png';
import envelopeClosed from '../assets/img/envelopeClosed.png';
import cardToShow from '../assets/img/card2.jpg';
import underEnvelope from '../assets/img/underEnvelope.png';
import { Redirect } from "react-router-dom";

const backgroundEnvelope = {
  background: `url(${underEnvelope}) center`,
  backgroundSize: "cover",
  position: "absolute",
  width: "100vw",
  height: "100vh",
  animation: "fadein 5s forwards",
};

const openedEnvelopePart = {
  background: `url(${openedEnvelope}) center`,
  backgroundSize: "cover",
  position: "absolute",
  width: "100vw",
  height: "100vh",
};

const card = {
  background: `url(${cardToShow}) center`,
  backgroundSize: "cover",
  position: "fixed",
  width: "10vw",
  height: "10vh",
  top: "55vw",
  left: "45vh",
};

const envelopeClosedPart = {
  background: `url(${envelopeClosed}) center`,
  backgroundSize: "cover",
  position: "absolute",
  width: "100vw",
  height: "100vh",
};

class Envelope extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      openedEnvelope: '',
      questionPopup: 'invisible',
      heartNumber: 0,
      cardToShow: 'invisible',
      mainEnvelope: '',
      wolves: 'invisible',
      volkiFade: '',
      toNextPage: false,
    };
    
    this.heartButton = [button1, button2];
    
    this.openEnvelope = this.openEnvelope.bind(this);
    this.changeBackground = this.changeBackground.bind(this);
    this.nextPage = this.nextPage.bind(this);
  };
  
  componentDidMount() {
    this.timeout = setTimeout(
      this.changeBackground,
      1000
    )
  };
  
  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout)
  };
  
  changeBackground() {
    this.setState(function ({heartNumber}) {
      const nextNumber = ++heartNumber % this.heartButton.length;
      
      return {heartNumber: nextNumber}
    }, function () {
      this.timeout = setTimeout(
        this.changeBackground,
        1000
      )
    })
  };
  
  openEnvelope() {
    this.setState({openedEnvelope: 'startOpenEnvelope'});
    this.removeCard();
  };
  
  removeCard() {
    this.setState({
      cardToShow: 'cardFromEnvelope',
      questionPopup: 'withDelay',
      mainEnvelope: 'removeEnvelope',
      wolves: 'volkiButton'
    });
  };
  
  nextPage() {
    this.setState({volkiFade: 'fading', toNextPage: true});
  }
  
  render() {
    if (this.state.toNextPage === true) {
      return <Redirect to='/hearts' />
    }
    return (
      <div className="bgUnderEnvelope">
        <div style={backgroundEnvelope}>
          <div style={card} className={this.state.cardToShow}/>
          <div style={openedEnvelopePart} className={this.state.mainEnvelope}>
            <div style={envelopeClosedPart} className={this.state.openedEnvelope}>
              <div className="buttonContainer">
                <input alt="" type="image" src={this.heartButton[this.state.heartNumber]}
                       className="animatedHeart buttonTransparent" onClick={this.openEnvelope}/>
              </div>
            </div>
          </div>
          <div style={{backgroundImage: `url(${openedEnvelope})`}}/>
          <div className={this.state.questionPopup}>
            <div className={this.state.volkiFade}>
              <h1>Je veux te dire quelques choses, ok? :)</h1>
                <input alt ="" type="image" className={this.state.wolves} src={wolfs} onClick={this.nextPage} />
            </div>
          </div>
        </div>
      </div>
    )
    
  }
}


export default Envelope;
