import React, { Component } from 'react';
import './App.css';
import './Components/Hearts.scss';
import Envelope from './Components/envelope.js';
import { Route } from 'react-router-dom';
import Hearts from "./Components/manyhearts";
import Yolo from "./Components/yolo";
import We from "./Components/we";

class App extends Component {
  
  render() {
    return (
      <div>
        <Yolo/>
        <Route path="/we" component={We} />
        <Route path="/envelope" component={Envelope} />
        <Route path="/hearts" component={Hearts} />
      </div>
    );
  }
}

export default App;
