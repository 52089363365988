import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import yolo from "../assets/img/yolo.png"

const yoloStart = {
  backgroundPosition: "center",
  position: "fixed",
  width: "20vw",
  height: "20vh",
  left: "40vw",
  top: "20vh",
};

const yoloStyle = {
  position: "fixed",
  width: "20vw",
  height: "20vw",
  left: "40vw",
  top: "30vh",
};

class Yolo extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      buttonToHide: '',
    };
    
    this.fadingFunction = this.fadingFunction.bind(this);
  };
  
  fadingFunction() {
    this.setState({buttonToHide: 'invisible'});
  };
  
  render() {
    return (
      <div className="bgUnderEnvelope">
        <div style={yoloStart} className={this.state.buttonToHide}>
          <Link to="/envelope">
            <input alt="" type="image" src={yolo} style={yoloStyle} onClick={this.fadingFunction} />
          </Link>
        </div>
      </div>
    );
  }
}

export default Yolo;
