import React from 'react';
import under from '../assets/img/underEnvelope.png';
import back from '../assets/img/back.png';
import {Link} from "react-router-dom";


const underWe = {
  background: `${under} center`,
  position: "fixed",
  width: "100vw",
  height: "100vh",
};

const underUnderWe = {
  background: "white center",
  position: "fixed",
  width: "100vw",
  height: "100vh",
};


class We extends React.Component {
  
  
  render() {
    return (
      <div style={underUnderWe}>
        <link rel="stylesheet"
              href="https://fonts.googleapis.com/css?family=Tangerine"/>
        <div style={underWe}>
          <div className="backgroundWe">
            <div className="textBeforeSwap">
              Je suis vraiment contente de notre couple... ^^
            </div>
            <div className="textSwap">
              ... et je veux être avec toi, mon loup d'amour.
            </div>
            <Link to="/">
              <input src={back} type="image" alt="" className="back"/>
            </Link>
          </div>
        </div>
      </div>
    
    )
  }
}

export default We

