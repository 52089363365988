import React, {Component} from 'react';
import violet from "../assets/img/violet.png";
import yellow from "../assets/img/yellow.png";
import turquoise from "../assets/img/torquoise.png";
import cherry from "../assets/img/cherry.png";
import green from "../assets/img/green.png";
import orange from "../assets/img/orange.png";
import pink from "../assets/img/pink.png";
import sky from "../assets/img/sky.jpg";
import star from "../assets/img/star.png";
import sun1 from "../assets/img/sun1.png";
import sun2 from "../assets/img/sun2.png";
import rainbow from "../assets/img/rainbow.png";
import smile from "../assets/img/smile.png";
import volk from "../assets/img/volk.png";
import beard from "../assets/img/beardblue.png";
import stylish from "../assets/img/loupStyle.png";
import strong from "../assets/img/strong.png";
import {Redirect} from "react-router-dom";

const underSky = {
  background: "black center",
  position: "absolute",
  width: "100vw",
  height: "100vh",
};

const backgroundSky = {
  animation: "fadein 5s forwards",
  background: `url(${sky}) center`,
  backgroundSize: "cover",
  position: "absolute",
  width: "100vw",
  height: "100vh",
};

class Hearts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      starStyle: 'positionStar',
      violetMoves: 'violet position6',
      cherryMoves: 'cherry position5',
      greenMoves: 'green position2',
      orangeMoves: 'orange position7',
      pinkMoves: 'pink position3',
      turquoiseMoves: 'turquoise position4',
      yellowMoves: 'yellow position1',
      textViolet: 'invisible',
      textCherry: 'invisible',
      textGreen: 'invisible',
      textOrange: 'invisible',
      textPink: 'invisible',
      textTurquoise: 'invisible',
      textYellow: 'invisible',
      rainbow: 'invisible',
      sun1: 'invisible',
      sun2: 'invisible',
      emoji: 'invisible',
      beard: 'invisible',
      stylish: 'invisible',
      strong: 'invisible',
      
    };
    this.heartViolet = this.heartViolet.bind(this);
    this.heartCherry = this.heartCherry.bind(this);
    this.heartGreen = this.heartGreen.bind(this);
    this.heartOrange = this.heartOrange.bind(this);
    this.heartPink = this.heartPink.bind(this);
    this.heartTurquoise = this.heartTurquoise.bind(this);
    this.heartYellow = this.heartYellow.bind(this);
    this.starReset = this.starReset.bind(this);
    
    this.smiles = [];
    this.cleaning = [];
    this.counts = 0;
    this.texts = [
      "J'adore quand tu souris ^^",
      "Tu es un loup super fort! Comme notre amour ;)",
      "Tu me donnes un arc-en-ciel des émotions",
      "J'aime beaucoup ton style",
      "Merci pour ton aide avec tout chaque jour...",
      "Tu es un Sunshine de ma vie",
      "Je t'aime.",
    ]
  };
  
  starReset(n) {
    setTimeout(() => {
      this.counts = this.counts + 1;
      this.setState({starStyle: 'positionStar', disabled: false})
    }, n*1000);
  };
  
  heartViolet() {
    this.setState({
      starStyle: 'positionStar starExpands',
      violetMoves: 'position6 heartsAnimated',
      textViolet: 'position6 text',
      disabled: true,
    });
    for (let i = 1; i < 100; i++) {
      let W = Math.floor(Math.random() * 10) + 10;
      let X = Math.floor(Math.random() * 100) - W;
      let Y = Math.floor(Math.random() * 100) - W;
      let D = Math.floor(Math.random() * 10000);
      let XYW = {
        left: `${X}vw`, top: `${Y}vh`, width: `${W}vw`, opacity: "0",
        animation: `smiles 3s ${D}ms forwards`, position: "absolute",
      };
      this.smiles.push(<img key={i} alt="" src={smile} style={XYW}/>);
    }
    this.starReset(12);
  };
  
  heartYellow() {
    this.setState({
      starStyle: 'positionStar starExpands',
      yellowMoves: 'position1 heartsAnimated',
      textYellow: 'position1 text',
      disabled: true,
      emoji: 'showLove',
    });
    this.starReset(7);
  };
  
  heartGreen() {
    this.setState({
      starStyle: 'positionStar starExpands',
      greenMoves: 'position2 heartsAnimated',
      textGreen: 'position2 text',
      disabled: true,
      rainbow: 'rainbow',
    });
    this.starReset(11);
  };
  
  heartCherry() {
    this.setState({
      starStyle: 'positionStar starExpands',
      cherryMoves: 'position5 heartsAnimated',
      textCherry: 'position5 text',
      strong: 'amourForte',
      disabled: true,
    });
    this.starReset(5);
  };
  
  heartTurquoise() {
    this.setState({
      starStyle: 'positionStar starExpands',
      turquoiseMoves: 'position4 heartsAnimated',
      textTurquoise: 'position4 text',
      disabled: true,
      sun1: 'sun1',
      sun2: 'sun2',
    });
    
    this.starReset(12);
  };
  
  heartOrange() {
    this.setState({
      starStyle: 'positionStar starExpands',
      orangeMoves: 'position7 heartsAnimated',
      textOrange: 'position7 text',
      beard: 'beardToShow',
      stylish: 'loupStyle',
      disabled: true,
    });
    this.starReset(10);
  };
  
  heartPink() {
    this.setState({
      starStyle: 'positionStar starExpands',
      pinkMoves: 'position3 heartsAnimated',
      textPink: 'position3 text',
      disabled: true,
    });
    this.cleaning.push(
      <div key="v">
        <div className="dirt"/>
        <div className="cleaner">
          <img alt="" src={volk} className="volk"/>
        </div>
      </div>);
    this.starReset(15);
  };
  
  render() {
    console.log(this.counts)
      if (this.counts === 7) {return <Redirect to='/we' /> }
    return (
      <div style={underSky}>
        <link rel="stylesheet"
              href="https://fonts.googleapis.com/css?family=Tangerine"/>
        <div style={backgroundSky}>
          <div>
            {this.smiles}
          </div>
          <img alt="" src={star} className={this.state.starStyle}/>
          <input alt="" type="image" className={this.state.yellowMoves} src={yellow} onClick={this.heartYellow}
                 disabled={this.state.disabled}/>
          <input alt="" type="image" className={this.state.greenMoves} src={green} onClick={this.heartGreen}
                 disabled={this.state.disabled}/>
          <input alt="" type="image" className={this.state.pinkMoves} src={pink} onClick={this.heartPink}
                 disabled={this.state.disabled}/>
          <input alt="" type="image" className={this.state.turquoiseMoves} src={turquoise}
                 onClick={this.heartTurquoise} disabled={this.state.disabled}/>
          <input alt="" type="image" className={this.state.cherryMoves} src={cherry} onClick={this.heartCherry}
                 disabled={this.state.disabled}/>
          <input alt="" type="image" className={this.state.violetMoves} src={violet} onClick={this.heartViolet}
                 disabled={this.state.disabled}/>
          <input alt="" type="image" className={this.state.orangeMoves} src={orange} onClick={this.heartOrange}
                 disabled={this.state.disabled}/>
          <img alt="" src={beard} className={this.state.beard}/>
          <img alt="" src={stylish} className={this.state.stylish} />
          <div className={this.state.textViolet} style={{paddingTop: "2vh"}}>{this.texts[0]}</div>
          <div className={this.state.textCherry} style={{paddingTop: "2vh"}}>{this.texts[1]}</div>
          <div className={this.state.textGreen} style={{paddingTop: "2vh"}}>{this.texts[2]}</div>
          <div className={this.state.textOrange} style={{paddingTop: "2vh"}}>{this.texts[3]}</div>
          <div className={this.state.textPink} style={{paddingTop: "2vh"}}>{this.texts[4]}</div>
          <div className={this.state.textTurquoise} style={{paddingTop: "2vh"}}>{this.texts[5]}</div>
          <div className={this.state.textYellow} style={{paddingTop: "2vh"}}>{this.texts[6]}</div>
          <img alt="" src={rainbow} className={this.state.rainbow}/>
          <div className={this.state.emoji}/>
          <img alt="" src={sun2} className={this.state.sun2}/>
          <img alt="" src={sun1} className={this.state.sun1}/>

          <div>
            {this.cleaning}
          </div>
          <img alt="" src={strong} className={this.state.strong} />
        </div>
      </div>
    
    )
  }
}

export default Hearts

